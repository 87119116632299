import {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_ePulse/helpers'
import '../../../App.css'

import {UserListTable} from './userListTable'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'

import {USER_LIST_QUERY} from '../../GraphQLQueries/User'

import useApi from '../../../hooks/useQuery'
import {checkPermission} from '../../App'
import {LoadingSpinner} from '../../../_ePulse/layout/components/LoadingSpinner'

export const Users = () => {
  const [dateFilter, setdateFilter] = useState<any>()
  const [startDate, setstartDate] = useState(moment(new Date()))
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'))
  const navigate = useNavigate()

  const {
    data: resData,
    loading: loading1,
    error: error1,
    refetch: refetch1,
  } = useApi(false, USER_LIST_QUERY, {
    endDate: moment(endDate).endOf('day').utc(),
    startDate: moment(startDate).startOf('day').utc(),
  })

  useEffect(() => {
    if (resData) {
      const res = resData?.getDashboardUserList
      setUsersList(res)
    }
  }, [resData])

  const [usersList, setUsersList] = useState([])

  const handleDateFilter = (value: string) => {
    setdateFilter(value)

    if (value === 'TODAY') {
      setstartDate(moment(new Date()).startOf('day'))
      refetch1({startDate: moment(new Date()).startOf('day').utc()})
    }
    if (value === 'WEEK') {
      setstartDate(moment(new Date()).subtract(1, 'weeks').startOf('day'))
      refetch1({startDate: moment(new Date()).subtract(1, 'weeks').startOf('day').utc()})
    }
    if (value === 'MONTH') {
      setstartDate(moment(new Date()).subtract(1, 'months').startOf('day'))
      refetch1({startDate: moment(new Date()).subtract(1, 'months').startOf('day').utc()})
    }
    if (value === 'YEAR') {
      setstartDate(moment(new Date()).subtract(1, 'years').startOf('day'))
      refetch1({startDate: moment(new Date()).subtract(1, 'years').startOf('day').utc()})
    }
  }

  return (
    <>
      <div className='dashboard-block dashboard-block-users'>
        <LoadingSpinner isLoading={loading1} />
        <div className='dashboard-block-header'>
          <div className='header-left'>
            <img
              src={toAbsoluteUrl('/media/ePulse/icons/dashboard/consumers.svg')}
              className='h-32px'
              alt=''
            />
            <span className='block-title'>Recent Users</span>
            {checkPermission('getPeginatedUserList') && (
              <button
                className='border-0 bg-white link-view-all'
                onClick={() => navigate('/users')}
                style={{cursor: 'pointer'}}
                // disabled={checkPermission('getPatientProfileList') ? false : true}
              >
                <span>View All</span>
              </button>
            )}
          </div>

          <div className='header-right'>
            <select
              className='border-0'
              value={dateFilter}
              onChange={(e: any) => handleDateFilter(e.target.value)}
              // disabled={checkPermission('getPatientListWithDateFilter') ? false : true}
            >
              <option value='TODAY'>Today</option>
              <option value='WEEK'>This Week</option>
              <option value='MONTH'>This Month</option>
              <option value='YEAR'>This Year</option>
            </select>
          </div>
        </div>
        <>
          <div className='dashboard-table-group'>
            <div className='dashboard-table-row dashboard-table-row-header'>
              <div className='d-flex  align-items-center col-3'>
                <div className='d-flex flex-column me-2 '>
                  <div className='d-flex flex-stack '>
                    <span className='col-label fw-bold'>Name</span>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-column me-2 col-2 justify-content-center'>
                <div className='d-flex flex-stack '>
                  <span className='col-label fw-bold'>Mobile Number</span>
                </div>
              </div>
              <div className='d-flex flex-column me-2 col-3  justify-content-center'>
                <div className='d-flex flex-stack '>
                  <span className='col-label fw-bold'>Email</span>
                </div>
              </div>
              <div className='d-flex flex-column me-2 col-2  justify-content-center'>
                <div className='d-flex flex-stack '>
                  <span className='col-label fw-bold'>Register Date</span>
                </div>
              </div>
              <div className='d-flex flex-column me-2 col-2  justify-content-center'>
                <div className='d-flex flex-stack '>
                  <span className='col-label fw-bold'>Actions</span>
                </div>
              </div>

              {/* <div className='d-flex flex-row me-2 col-2 justify-content-center '>
                <div className='d-flex flex-stack '>
                  <span className='col-label fw-bold'>Actions</span>
                </div>
              </div> */}
            </div>
            <div
              className='dashboard-block-body'
              style={{height: '270px', overflow: 'hidden auto'}}
            >
              {!usersList.length ? (
                <div className='d-flex flex-row align-items-center justify-content-center h-100'>
                  No User Data Found
                </div>
              ) : (
                <>
                  {usersList.map((user: any, index: number) => {
                    return <UserListTable user={user} key={index} />
                  })}
                </>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  )
}
