import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import '../../../../App.css'
import {useQuery, gql, useMutation} from '@apollo/client'
import {UPDATE_ADMIN_PASSWORD} from '../../../GraphQLQueries/Auth'
import {toast} from 'react-toastify'
import {ToastMessageContainer} from '../../../../_ePulse/layout/components/ToastMessageContainer'
import {success} from '../../../../data/Status/success'
import useMutationApi from '../../../../hooks/useMutation'
import {LoadingSpinner} from '../../../../_ePulse/layout/components/LoadingSpinner'
const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

const passwordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string().required('New Password is required'),
  confirmPassword: Yup.string().required('Confirm Password is required'),
})

export function UpdatePassword() {
  const navigate = useNavigate()
  const [isloading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [hasErrorsmsg, setHasErrorsmsg] = useState<any>('')

  const {createData, loading, error, data} = useMutationApi(UPDATE_ADMIN_PASSWORD)

  useEffect(() => {}, [])

  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      if (values.newPassword !== values.confirmPassword) {
        setHasErrors(true)
        setHasErrorsmsg('Password & Confirm Password are not same')
        setLoading(false)
      } else {
        try {
          const respone = await createData({
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
          })

          toast.success(success.PASSWORD_UPDATED)

          initialValues.confirmPassword = ''
          initialValues.newPassword = ''
          initialValues.oldPassword = ''
          setTimeout(() => {
            setLoading(false)
            navigate('/dashboard')
          }, 2000)

          // navigate(`/auth`)
        } catch (error: any) {
          console.log('ERROR......', error)
          setHasErrorsmsg(error?.message)
          setHasErrors(true)
          setLoading(false)
        }
      }
    },
  })

  return (
    <>
      <ToastMessageContainer />
      <LoadingSpinner isLoading={loading} />
      <div className='bg-white rounded p-5'>
        <div className='d-flex flex-column justify-content-between mb-4 border-bottom pb-4 border-muted'>
          <span className='fs-1 fw-bold '>Fill form to update the password</span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{hasErrorsmsg}</div>
            </div>
          )}

          {/* {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{hasErrorsmsg}</div>
          </div>
        )} */}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-5'>
            {/* <div className='d-flex flex-column col mx-4 mb-3 '>
              <span className='fs-5 mb-2 required col-lg-3'>Old Password</span> */}
            <div className='d-flex flex-column col mx-4 mb-3'>
              <span className='fs-5 mb-2 required '>Old Password</span>
              <div className='d-flex flex-column col'>
                <input
                  type='password'
                  placeholder=''
                  {...formik.getFieldProps('oldPassword')}
                  className={clsx('form-control form-control-lg form-control-solid')}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.oldPassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className='d-flex flex-column col mx-4 mb-3 '>
              <span className='fs-5 mb-2 required col-lg-3'>New Password</span> */}
            <div className='d-flex flex-column col mx-4 mb-3'>
              <span className='fs-5 mb-2 required '>New Password</span>
              <div className='d-flex flex-column col'>
                <input
                  type='password'
                  placeholder=''
                  {...formik.getFieldProps('newPassword')}
                  className={clsx('form-control form-control-lg form-control-solid')}
                />
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.newPassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className='d-flex flex-column col mx-4 mb-3 '>
              <span className='fs-5 mb-2 required col-lg-3'>Confirm Password</span> */}
            <div className='d-flex flex-column col mx-4 mb-3'>
              <span className='fs-5 mb-2 required '>Confirm Password</span>
              <div className='d-flex flex-column col'>
                <input
                  type='password'
                  placeholder=''
                  {...formik.getFieldProps('confirmPassword')}
                  className={clsx('form-control form-control-lg form-control-solid')}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirmPassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='btn-row'>
            <button type='submit' id='kt_password_reset_submit' className='ePulse-btn-primary'>
              <span className='indicator-label text-white'>Update Password</span>
              {isloading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='ms-3 ePulse-btn-secondory'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <Link to='/dashboard' className='text-dark'>
                Home
              </Link>
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </>
  )
}
